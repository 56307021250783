import React, { useState, useEffect } from 'react';
import logo from '../data/logo-title.png'


const NavBar = ({ bgColor, btnColor, noBanner }) => {
    const [isShown, setIsShown] = useState('hide')
    const [slideAnin, setSlideAnim] = useState("slideOut")
    const [mobileStyle, setMobileStlye] = useState("")
    const [cover, setCover] = useState("none")
    const size = parseFloat(getComputedStyle(document.documentElement).fontSize) * 40 // For 40 Rem media in the CSS file

    useEffect(() => {
        window.addEventListener('scroll', showNav)
        window.addEventListener('resize', HandleResize)
        HandleResize()  
        
        // return () => {
        //     window.removeEventListener('scroll', showNav)
        //     window.removeEventListener('resize', HandleResize)
        // }
    }, [])

    const HandleResize = () => {
        HandleMobile()
    }

    const HandleMobile = () => {
        window.innerWidth < size ? setMobileStlye("full-right") : setMobileStlye("")
        setSlideAnim('slideOut')
        setCover("none")
    }

    const showNav = () => {
        if (window !== undefined) {
            let windowHeight = window.scrollY
            windowHeight > 500 ? setIsShown("show") : setIsShown('hide')
        }
    }
    
    const content = (className) => {
        return (
            <div className={className}>
                <div className="content-center p-1">
                    {/* T.A.M.E.@T.I.M.E. */}
                    <a href="/"><img src={logo} alt="T.A.M.E.@T.I.M.E." className="max-width max-height"/></a>
                </div>

                <div className="content-center p-1 align-center">
                    <div className="flex gap-1 txt-center fsnav">
                        <a href="/"><span className="ctxt-primary block">Home</span></a>
                        <a href="/health"><span className="ctxt-primary block">Health</span></a>
                        <a href="/mind"><p className="ctxt-primary block">Mind</p></a>
                        <a href="/body"><p className="ctxt-primary block">Body</p></a>
                        <a href="/life"><p className="ctxt-primary block">Life</p></a>
                        <a href="/skin"><p className="ctxt-primary block">Skin</p></a>
                        {/* <a href="/about"><p className="ctxt-primary">About</p></a> */}
                    </div>
                </div>


                { window.innerWidth > size ? ( 
                <div className="align-center pr2 flex justify-end">
                    <a href="https://squareup.com/gift/G1FQ2PM9NQSRK/order"><Card color={btnColor} className="txt-center hover fstxt-s mr1">
                        <span>Gift Card</span>
                    </Card></a>
                    <a href="#book-appointment"><Card color={btnColor} className="txt-center hover fstxt-s">
                        <span>Book Now</span>
                    </Card></a>
                </div>
                ): ( 
                    <div style={{display: 'none'}}></div>
                )}
            </div>
        )
    }

    const Slide = () => {
        if (slideAnin === "slideIn") {
            setSlideAnim("slideOut")
            setMobileStlye("full-right slideOut")
            setCover("none")
        } else {
            setSlideAnim("slideIn")
            setMobileStlye("half-right slideIn")
            setCover("block")
        }
    }

    let CSSWidthClass = ""
    if (noBanner) CSSWidthClass="max-srceen-width"

    return (
        <div>
            <div className="fixed max-srceen-width max-srceen-height" style={{background: "rgba(0,0,0,0.7)", zIndex: 10, display: cover}}></div>
            <div className={`fixed navbar ${mobileStyle}`}>
                { window.innerWidth < size ? ( // Mobile: 10}}>
                    <div className={`${bgColor} max-srceen-height half-srceen-width`}>
                        {content("inline-block line-height-4")}
                        <div className="fixed inline-block" style={{width:"20px", height:"17px", margin:"10px"}} onClick={Slide}>
                            <div className={bgColor} style={{width: "100%", height: "5px", borderRadius:"15px"}}></div>
                            <div className={bgColor} style={{width: "100%", height: "5px", borderRadius:"15px", marginTop: "1px"}}></div>
                            <div className={bgColor} style={{width: "100%", height: "5px", borderRadius:"15px", marginTop: "1px"}}></div>
                        </div>

                        <div className="absolute max-width p-2" style={{bottom:10}}>
                            <a href="https://squareup.com/gift/G1FQ2PM9NQSRK/order"><Card color={btnColor} className="txt-center hover fstxt-s m-2">
                                <span>Gift Card</span>
                            </Card></a>
                            <a href="#book-appointment"><Card color={btnColor} className="txt-center hover fstxt-s m-2">
                                <span>Book Now</span>
                            </Card></a>
                        </div>
                    </div>
                ) : ( // Bigger Screens
                    content(`grid ${bgColor} ${isShown} ${CSSWidthClass}`)
                )}
            </div>
        </div>
    )
}

const CopyRight = () => {
    return (
        <div className="txt-center p-1">
            © T.A.M.E. TIME.® All Rights Reserved. Web Design & Internet Marketing by Swoofz
        </div>
    )
}

const Title = (props) => {
    const { type, children } = props

    return (
        <div>
            <h1 className={`papyrus fstitle-l txt-center ctxt-${type} pt2`}>T.A.M.E. {type}</h1>
            <h3 className={`fstitle-m txt-center ctxt-${type} pb2`}>{children}</h3>
        </div>
    )
}

const Card = (props) => {
    const { maxWidth, color, children, className } = props

    return (
        <div className={`${color} ctxt-primary p-1 border-radius-sm box-shadow-3 content-center ${className}`} style={{maxWidth: maxWidth + "px"}}>
            {children}
        </div>
    )
}


export { CopyRight, Title, NavBar, Card }