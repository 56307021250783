import portrait from '../../data/portrait.jpg';
import { Card, NavBar, CopyRight } from '../const'
import BookNow from '../Book'


function Home() {
  return (
    <div id="home">
      <NavBar bgColor="skin" btnColor="health" noBanner={true}/>

      <section id="hero">
        <div className="absolute max-width">
          <div className="relative uppercase txt-center ctxt-primary max-width ctxt-stroke-sm txt-shadow-1 fshero-l">
            <p className='papyrus'>We can't stop time</p>
            <p className='papyrus'>But we can...</p>
          </div>

          <div className="uppercase txt-center ctxt-primary max-srceen-width contect-center arial-narrow p-1 txt-shadow-4 ctxt-stroke-sm fadeIn-5 fshero-m w-maxium-4 health border-radius-l">
            <p className="pb1">Age gracefully...</p>
            <p>Preventative & Restorative</p>
            <p>Health & Beauty</p>
          </div>
        </div>

        <video className="relative max-width" autoPlay muted src={`${process.env.PUBLIC_URL}/data/TameTime.mp4`} type="video/mp4" />
      </section>

      <section id="about" className="auto-margin mw-8">
        <div className="max-width grid col-1-2">
            <img className="portrait auto-margin border-radius-sm box-shadow-4 mb1" src={portrait} alt={"Dr. Tatyana Picture"} />

            <Card color="health" maxWidth={700} className="auto-margin">
              <h2 className="underline auto-margin p-1 txt-center fstxt-l papyrus">
                  About Dr.Tatyana
                </h2>
                <p className='fstxt-s'>
                Dr. Tatyana is a certified and licensed expert in Holistic Healthcare, Acupuncture, and Esthetics. Driven by a passion for preventative health and a thirst for knowledge, she has dedicated her career to helping individuals achieve optimal health and well-being.
                </p>
                <br />
                <p className='fstxt-s'>
                  Dr. Tatyana's practice focuses on holistic health, specializing in gut health, pain management, and addressing root causes of health issues. She blends her multicultural background with traditional Chinese and Korean medicine, acupuncture, 5 element theory, and evidence-based functional medicine for comprehensive patient care. Her intuition and commitment, combined with patient compliance, ensure a high success rate in improving health. {/*<span><a href="/about" className="uppercase fstxt-xs">Learn More.</a></span>*/}
                </p>
            </Card>
        </div>
      </section>

      <section id="services" className="skin-light">
        <h2 className="underline txt-center pt2 ctxt-skin fstxt-l papyrus">Solutions We Provide</h2>

        <div className="grid auto-margin mw-8 col-3 gap-1 mt1 justify-center">
          {/* HEALTH CARD */}
          <Card color="health" maxWidth={300}>
            <a href="/health" className="ctxt-primary"><h3 className="txt-center papyrus underline fstxt-m">Health</h3></a>
            <p className='fstxt-s'>
              An integrated root cause approach to your customized care, combining holistic Asian and Functional medicine principles.
            </p>
            <br />
            <p className='fstxt-s'>Modalities include:</p>
            <ul className='grid justify-center fstxt-s'>
              <li>Functional Labs</li>
              <li>Personalized Nutrition</li>
              <li>Acupuncture, Cupping, & PEMF</li>
              <li>Herbal & Nutraceutical Medicine</li>
            </ul>
            <div className="uppercase pt2">
              <a href="/health" className="fstxt-xs halfw inline-block">Learn More </a>
              <a href="#book-appointment" className="txt-right fstxt-xs halfw inline-block">Book Appointment</a>
            </div>
          </Card>

          {/* MIND BODY AND LIFE Links on CARD */}
          <Card color="health" maxWidth={300}>
            <ul className='txt-center bold no-bullets line-height-5 fstxt-m'>
              <a href="/mind"><li className="ctxt-primary papyrus">Mind</li></a>
              <a href="/body"><li className="ctxt-primary papyrus">Body</li></a>
              <a href="/life"><li className="ctxt-primary papyrus">Life</li></a>
            </ul>
          </Card>

          {/* SKIN CARD */}
          <Card color="health" maxWidth={300}>
          <a href="/skin" className="ctxt-primary"><h3 className="txt-center papyrus underline fstxt-m">Skin</h3></a>
            <p className='fstxt-s'>
              Enhanced skin health and appearance with custom facial protocols with Korean and organic skincare along with advanced techniques:
            </p>
            <ul className='pl4 fstxt-s'>
              <li>microdermabrasion</li>
              <li>microcurrent therapy</li>
              <li>LED light therapy</li>
              <li>micro needling</li>
              <li>additional personalized treatments</li>
            </ul>
            <div className="uppercase pt2">
              <a href="/skin" className="fstxt-xs halfw inline-block">Learn More </a>
              <a href="#book-appointment" className="txt-right fstxt-xs halfw inline-block">Book Appointment</a>
            </div>
          </Card>
          
        </div>
      </section>

      <BookNow 
        txtColor={"ctxt-skin"} 
        cardColor={"skin"} 
        btnColor={"rgb(41, 97, 121)"}
      />

      <CopyRight />
    </div>
  );
}

export default Home;