import Container from "../containers";
import { Title, NavBar, CopyRight } from "../const"
import BookNow from "../Book";


function Health(props) {
  const { images } = props;
  const oligoImages = {
    logo: images.oligo,
    test: images.ogoTest,
    review: images.ogoReview
  }

  return (
    <div id="health" className="ctxt-health fstxt-s">
        <NavBar bgColor="health" btnColor="skin"/>
        <Hero image={images.bed}/>
        <FunctionalMedicine image={images.tamepak}/>
        <OligoScan images={oligoImages}/>
        <Nexi image={images.nexi}/>
        <Acupuncture image={images.acupuncture}/>
        <Herbs image={images.herbs}/>
        <Cupping image={images.cupping}/>
        <Aromatherapy image={images.aromatherapy}/>
        <Mat image={images.mat}/>
        <BookNow 
            txtColor={"ctxt-health"} 
            cardColor={"health"} 
            btnColor={"#176485"} 
            bgColor={"health-light"}
        />
        <CopyRight/>
    </div>
  );
}

// Page Sections
const Hero = ({ image }) => {
    const link = <a href="#functional" className="ctxt-health underline">
    Functional and Western Lab Testing/Analysis</a>
    const info = [
        ["", "Wellness and Preventative Consulting"], 
        ["", link], 
        ["", "Systemic Detoxification (Remove, Replete, Restore Gut Health)"], 
        ["", "Personalized Anti-Inflammatory Nutrition"], 
        ["", "Acupuncture, Cupping, & PEMF"], 
        ["", "Custom Tailored Herbal & Nutraceutical Medicine"], 
        ["", "Complementary Alternative Medicine – Aromatherpy"], 
        ["", "and MORE!"],
    ]

    return (
        <section id="hero" className="ph5">
            <Title type="health"> Tatyana's Alternative Medicine Effects </Title>

            <div className="grid gap-1 col-2">
                <div className="content-center grid gap-1">
                    <p>
                        An integrated root cause approach to your customized care, combining holistic Asian and Functional medicine principles.
                    </p>

                    <p>Modalities include:</p>
                    <ContentList
                        content={info}
                        listclass="ph5"
                    />
                </div>

            <img className="auto-margin h-maxium-4" src={image} alt="bed" />
            </div>
        </section>
    )
}

const FunctionalMedicine = ({ image }) => {
    const principles = [ // [ Main Point, info ]
        ["Personalized Care:", "We treat each patient as a unique individual, tailoring our approach to their specific genetic, environmental, and lifestyle factors."], ["Holistic Approach:", "We believe in treating the whole person—body, mind, and spirit—to achieve optimal health and well-being."], ["Systems Biology:", "Viewing the body as interconnected systems, we strive to restore balance for long-term health."], ["Identifying Root Causes:", "Rather than just alleviating symptoms, we focus on uncovering and addressing the underlying factors contributing to illness."], ["Integrative Therapies:", "We combine the best of conventional medicine with alternative and complementary treatments to support your journey to health."], ["Patient Empowerment:", "We emphasize educating and empowering patients to take an active role in their own health and wellness."]
    ]
    const eastMeetsWest = [ // [ Title, info ]
        ["Holistic Perspective", "Both Functional Medicine and TCM recognize that health is influenced by various factors such as lifestyle, diet, environment, emotions, and genetics. By addressing the whole person, rather than just symptoms, we can uncover underlying imbalances and promote true healing."], ["Root Cause Analysis", "Functional Medicine dives deep to identify and treat the root causes of illness, aligning perfectly with TCM’s philosophy of addressing the underlying imbalances causing health issues. Through methods like pulse diagnosis and tongue examination, TCM offers valuable insights into your health that complement modern diagnostic tools."], ["Individualized Treatment", "Your health journey is unique, and both Functional Medicine and TCM emphasize personalized treatment plans tailored to your specific needs. From advanced genetic testing and hormone panels in Functional Medicine to the ancient principles of Yin-Yang balance and the Five Elements theory in TCM, we ensure your treatment plan is as unique as you are."], ["Combination of Therapies", "By integrating TCM with Functional Medicine, we expand your treatment options to include herbal medicine, acupuncture, dietary therapy, and mind-body practices alongside conventional medical treatments. This comprehensive approach enhances your healing potential and promotes overall wellness."], ["Patient-Centered Care", "Empowerment is key in both Functional Medicine and TCM. We encourage you to take an active role in your healing journey, making informed decisions and adopting practices that support your long-term health and well-being."], ["Comprehensive Healthcare", "Embrace a blend of modern scientific knowledge and ancient holistic principles at T.A.M.E. TIME. Our integrated approach addresses the root causes of illness, enhances treatment outcomes, and promotes balance in your life."],
    ]

    const TitleInfo = ({ content }) => {
        const [title, info] = content
        return (
            <div>
                <h2 className="txt-center fstxt-m pb1 papyrus">{title}</h2>
                <p>{info}</p>
            </div>
        )
    } 

    function CombineBenefits() {
        return (
            <div className="grid gap-1 col-3 p-1">
                {eastMeetsWest.map((content, index) => (
                     <Container type="box" bg={[41, 97, 121]} alpha={0.3}
                        className="border-radius-sm max-height content-center"
                        key={index}
                    >
                        <TitleInfo content={content}/>
                    </Container>
                ))}
            </div>
        )
    }

    return (
        <section id="functional" className="health-light mt3 p-1">
            <SectionTitle className="ml1 pt1 pr2 mb1">Functional Medicine</SectionTitle>

            <p className="p-2">
                Functional medicine is a patient-centered approach to healthcare that goes beyond just treating symptoms—it focuses on identifying and addressing the root causes of disease. By considering a person's genetic makeup, environment, and lifestyle factors, we create personalized treatment plans aimed at restoring balance and promoting overall wellness.
            </p>

            <Container
                type='box'
                className="grid gap-1 col-2"
            >
                <img src={image} alt="functional medicane pack" className="auto-margin w-maxium-4 max-width p-1"/>
                <div>
                    <h2 className="fstxt-m pt2 papyrus">Key Principles of Functional Medicine:</h2>

                    <ContentList
                        content={principles}
                        listclass="no-bullets pt2 ph5"
                        itemclass="pb1"
                    />
                </div>
            </Container>

            <Container type='box'>
                <h2 className="underline pv2r fstxt-l papyrus">East Meets West</h2>
                <p className="pv2r">
                    Discover the Synergy of Functional Medicine and Traditional Chinese Medicine (TCM)
                </p>

                <p className="pv2r">
                    Dr. Tatyana believes in combining the best of both worlds—Functional Medicine and Traditional Chinese Medicine (TCM)—to provide you with a holistic approach to health and wellness. Here’s why integrating these two disciplines can benefit you:
                </p>

                {CombineBenefits()}

                <p>
                    Discover the power of Functional Medicine and TCM working together for your health and well-being. Contact us today to embark on your personalized journey to optimal wellness.
                </p>
            </Container>
        </section>
    )
}

const OligoScan = ({ images }) => {
    const features = [
        ["Comprehensive Analysis:", "Measures intracellular levels of 44 elements, including 21 minerals, 16 heavy metals, and vitamins A, B6, B9, B12, C, D, and E."],
        ["Immediate Results:", "Utilizes advanced spectroscopy to deliver real-time data without the need for needles or laboratory work."],
        ["Non-Invasive Procedure:", "Assesses tissue at a depth of 4mm through the skin, ensuring a painless experience."],
        ["Personalized Insights:", "Helps identify imbalances that may affect your energy, skin, digestion, and overall health."],
    ]

    return (
        <section id="oligoscan" className="">
            <div className="grid gap-1 col-2">
                <Container pos="col-start-2" type="box">
                    <img src={images.logo} alt="oligoscan logo" className="block center"/>

                    <div className="ph5">
                        <p className="pt2">
                            Discover the power of OligoScan, a revolutionary, non-invasive testing method that provides immediate insights into your body's mineral levels and heavy metal exposure.
                        </p>

                        <p className="bold pt2 fstxt-m papyrus">Key Features of OligoScan:</p>
                        <ContentList content={features} listclass="ph5 pt1"/>

                        <p className="pt2">
                            With over 11 years of cutting-edge testing methodology, OligoScan offers fast and reliable results, accessible anywhere with a good internet connection. To perform the test, we require basic information such as your date of birth, gender, blood type, height, and weight. Experience the benefits of OligoScan and take a proactive step towards understanding your health.
                        </p>
                    </div>
                </Container>

                <div className="relative row-start-1">
                    <img src={images.test} alt="oligoscan pictures" className="max-width max-height"/>
                    <img src={images.review} alt="oligoscan pictures" style={{ position: 'absolute', bottom: 10, right: 10,  maxWidth: '50%' }} />
                </div>
            </div>
        </section>
    )
}
// TODO:
const Nexi = ({ image }) => {  }

const Acupuncture = ({ image }) => {
    const treating = [
        ["• Pain", "(all types)"], ["• Stress & Emotional issues", ""], 
        ["• Digestive disorders", ""], ["• Skin disorders", ""], 
        ["• Cold & Flu", ""], ["• Preventative Health", ""]
    ]

    return (
        <section id="acupuncture" className="bg-img grid gap-1 p-1 mt3"
            style={{ backgroundImage: `url(${image})` }}
        >
            <SectionTitle className="grid justify-end pt1 pr2">Acupuncture</SectionTitle>

            <Container
            type="circle"
            className="half-border-radius wfit-content w-maxium-6 p-7"
            alpha={0.6}
            hasGradient={true}
            >
                <div className="wfit-content">
                    <p className="txt-center p-1 fstxt-m"><strong className="papyrus">Acupuncture: Your Path to Natural Healing</strong></p>
                    <p className="txt-center">
                        Acupuncture uses fine needles placed precisely to activate your body’s natural healing processes. By stimulating nerves and improving blood flow, acupuncture helps: 
                    </p>

                    <ContentList 
                        listclass="no-bullets ph2 pt1"
                        content={[
                            ["• Release Endorphins:", "Your body’s natural painkillers"], 
                            ["• Enhance Nerve Signals:", "To boost self-healing."]
                        ]}
                    />

                    <p className="pt2 ph2 pb1">Effective for treating:</p>
                    <ContentList content={treating} listclass="no-bullets ph5"/>

                    <p className="txt-center p-1">
                        Experience the benefits of acupuncture, with or without Chinese herbal formulas, for a healthier, more balanced you! 
                    </p>
                </div>
            </Container>

            <Container
            alpha={0}
            type="emptybox"
            pos="grid justify-end"
            className="w-maxium-6"
            >
                <p className="pb1 fstxt-m">
                    <strong>Acupuncture Precautions</strong>
                </p>
                <p>
                    Acupuncture might not be suitable for everyone. If you have a bleeding disorder (like hemophilia) or are on blood thinners (e.g., warfarin), there's an increased risk of bleeding or bruising. Always consult with your healthcare provider to ensure acupuncture is safe for you.
                </p>
            </Container>
        </section>
    )
}

const Herbs = ({ image }) => {
    const functionality = [
        ["Emperor (Chief):", "The main ingredient addressing the primary health issue."], ["Minister (Deputy):", "Supports the emperor and targets secondary conditions."], ["Assistant:", "Has multiple roles—reinforces or moderates other ingredients, and addresses less critical issues."], ["Servant (Envoy):", "Directs the formula’s action to specific areas and harmonizes the blend."],
    ]

    const formula = [
        ["Reinforcement:", "Enhances therapeutic effects."], ["Potentiation:", "Boosts overall efficacy"], ["Restraint:", "Balances or moderates effects."], ["Detoxification:", "Reduces harmful side effects."], ["Counteraction:", "Neutralizes adverse reactions."], ["Toxicity:", "Manages or minimizes toxic effects."],
    ]

    return (
        <section id="herbs" className="health-light mt3">
            <SectionTitle className="ml1 pt1 pr2 mb1">Chinese Herbs</SectionTitle>

            <div className="grid gap-1 col-2">
                <Container type='box' pos="herb-info">
                    <p className="pb1 fstxt-m">
                        <strong className='papyrus'>Chinese Herbal Medicine: Time-Tested Healing</strong>
                    </p>
                    <p>
                        Chinese herbs have been a cornerstone of Asian medicine for centuries. With over 5,700 substances—including plants, animals, and minerals—formulated into teas, granules, patents, and tinctures, this tradition has evolved into a sophisticated system. Today’s Chinese pharmacopeia includes 1,800 herbs, each classified by their energetic qualities and targeted functions.
                    </p>
                </Container>

                <img
                    className="auto-margin max-width p-1 herb-info"
                    src={image} alt="herbs"
                />

                <Container type="box" pos="herb-info row-span-2">
                    <p className="pb1"><strong>How It Works:</strong></p>
                    <ContentList listclass="ph5" content={functionality}/>

                    <p className="pb1 pt1"><strong>Herbal Formulations:</strong></p>
                    <p>Combining herbs follows six basic interaction modes:</p>
                    <ContentList listclass="ph5 pt1 pb1" content={formula}/>
                    
                    <p>
                        Custom formulas are crafted based on these principles to meet individual needs safely and effectively. 
                    </p>
                </Container>
            </div>
        </section>
    )
}

const Cupping = ({ image }) => {
    const benefits = [
        ["Respiratory Issues:", "Cough, bronchial congestion, asthma"], ["Digestive Health:", "Complaints and discomfort"], ["Pain Relief:", "Back pain, arthritis, fibromyalgia"], ["Emotional Well-being:", "Anxiety, depression, insomnia"], ["Circulation:", "Varicose veins, high blood pressure"], ["Skin Conditions:", "Acne, eczema, shingles"],
    ]

    return (
        <section id="cupping" className="grid gap-1 p-1 col-2-3r mt2">
            <div className="cupping-info">
                <SectionTitle className="ml1 pt1 pr2 mb1 grid justify-center" color={[41, 97, 121]} alpha={0.1}>Cupping</SectionTitle>
                {/* Under title */}

                <div className="grid justify-center auto-margin">
                    <p className="pt1"><strong>Benefits of Cupping:</strong></p>
                    <ContentList listclass="ph3 pt1" content={benefits}/>
                </div>
            </div>

            {/* Side info */}
            <Container
            type="box"
            bg={[41, 97, 121]}
            alpha={0.3}
            className="border-radius-sm max-height content-center"
            pos="cupping-info"
            >
                <p className="fstxt-m"><strong className="papyrus">Cupping Therapy: Revitalize and Heal</strong></p>

                <p className="pt1">
                    Cupping is a traditional Chinese medicine technique designed to clear stagnation and boost the flow of qi (vital energy) throughout the body. By creating suction and placing cups on the skin, cupping draws toxins and impurities to the surface, loosens connective tissue, and enhances blood flow, leading to relaxation and improved cell communication. 
                </p>

                <div className="health mt2 mw-8 border-radius-sm" style={{padding:"1px"}}></div>

                <p className="pt2 pb1"><strong>How It Works:</strong></p>

                <p>
                    Research shows that cupping reduces inflammatory cytokines (pain-causing chemicals) and increases healing cytokines. Although cupping may cause temporary bruising or soreness, this typically indicates the successful removal of toxins and stagnation. 
                </p>
            </Container>

            {/* Bottom */}
            <div className="cupping-info col-span-2">
                <img
                    className="auto-margin max-width p-1"
                    src={image}
                    alt="bed"
                />

                <p><strong>Caution:</strong></p>
                <ContentList listclass="ph5 pt1 pb1" content={[
                    ["Bleeding Disorders:", "Not recommended for those with hemophilia or on anticoagulants."], ["Skin Conditions:", "Avoid areas with active inflammation, burns, infection, or open wounds."]
                ]}/>
                <p>Experience the benefits of cupping therapy for a revitalized and balanced body.</p>
            </div>
        </section>
    )
}

const Aromatherapy = ({ image }) => {
    const benefits = [
        ["• Manage Pain", ""], ["• Improve Sleep Quality", ""], ["• Reduce stress, Agitation, and Anxiety", ""], ["• Soothe Sore Joints", ""], ["• Treat Headaches and Migraines", ""], ["• Alleviate Chemotherapy Side Effects", ""], ["• Fight Bacteria, Virus, or Fungus", ""], ["• Improve Digestion", ""], ["• Boost Immunity", ""],
    ]
    const help = [
        ["• Asthma", ""], ["• Insomnia", ""], ["• Fatigue", ""], ["• Depression", ""], ["• Inflammation", ""], ["• Peripheral neuropathy", ""], ["• Menstrual Issues", ""], ["• Alopecia", ""], ["• Arthritis", ""], ["• Menopause", ""],
    ]

    return (
        <section id="aromatherapy" className="p-1 mt2 health-light">
            {/* Change this class */}
            <Container type="box" className="aro circle-back">
                <h2 className="relative fstxt-l pb1 papyrus" style={{background: "none"}}>
                    AROMATHERAPY<span className="fstxt-s italic"> Included in any Skin &/or Acupuncture Treatment</span>
                </h2>

                <p className="fstxt-m">
                    <strong className="papyrus">Natural Healing with Essential Oils</strong>
                </p>

                <p>
                    Aromatherapy uses aromatic essential oils derived from plants to promote holistic healing. With roots in ancient China, India, Egypt, and other cultures, it blends art and science to offer both physical and psychological benefits.
                </p>
            </Container>

            <div className="grid gap-1 col-2">
                <img
                    className="max-width p-1 half-border-radius auto-margin"
                    src={image}
                    alt="herbs"
                />

                <div className="row-span-2">
                    <Container type="box"  bg={[41, 97, 121]} alpha={0.3}
                    pos="auto-margin w-maxium-4 m-1a" className="border-radius-sm content-center">
                        <p><strong>Benefits of Aromatherapy:</strong></p>
                        <ContentList listclass="no-bullets bold pt1 pl2" content={benefits}/>
                    </Container>

                    <Container type="box"
                    bg={[41, 97, 121]}
                    alpha={0.3}
                    pos="auto-margin w-maxium-25 m-1a"
                    className="border-radius-sm max-height content-center">
                        <p className="bold">Aromatherapy May Help:</p>
                        <ContentList listclass="no-bullets bold pt1 pl2" content={help}/>
                    </Container>
                </div>

                <Container type="box">
                    <p className="aro-footer">
                        <strong>Note: </strong>While aromatherapy shows promise, scientific research is still limited in areas like Alzheimer’s, Parkinson’s, and heart disease. 
                    </p>
                </Container>
            </div>
        </section>
    )
}

const Mat = ({ image }) => {
    // Content
    const pulsed = [
        ["Stimulates Well-being:", "Uses electromagnetic fields to enhance overall health."], ["Neutralizes Electromagnetic Smog:", "Helps counteract harmful environmental exposure."], ["Dr. Oz Featured:", "Recognized as a breakthrough in medical technology."], ["Natural Frequency:", "Based on Earth’s 7.83 Hz frequency, aligning with your body’s natural rhythms."]
    ]
    const farInfrared = [
        ["Penetrates Deeply:", "Far infrared rays reach 4-6 inches into tissues."], ["Relieves Pain:", "Helps reduce pain, inflammation, and stiffness."], ["Boosts Circulation:", "Enhances localized blood flow where applied."]
    ]
    const negative = [
        ["Nature’s Purifiers:", "Cleans the air of harmful agents and counteracts positive ion disturbances."], ["Detoxifies:", "Attracts airborne particles and aids in waste removal."], ["Enhances Wellness:", "Improves mind and body quality through natural ion release."]
    ]
    const hotStone = [
        ["Muscle Relaxation:", "Heated stones soothe muscles and relieve tension."],
        ["Reduces Stress:", "Linked to lower levels of stress and anxiety."],
        ["Enhances Massage:", "Makes the body more receptive to massage therapy."],
        ["Amplifies Other Therapies:", "Stones release far infrared rays and negative ions, boosting the effectiveness of additional therapies."],
    ]

    const data = [ // Title , content, className
        ["Pulsed Electromagnetic Field Therapy (PEMF):", pulsed, "auto-margin row-start-3 col-start-1"], ["Far Infrared Therapy:", farInfrared, "auto-margin col-start-1"], ["Negative Ion Therapy:", negative, "auto-margin"], ["Hot Stone Therapy:", hotStone, "auto-margin"],
    ]

    const Therapy = ({ title, content, className}) => {
        return (
            <Container
                type="circle" alpha={0.2}  bg={[41, 97, 121]} 
                pos={className} className="w-maxium-4 p-1 border-radius-sm"
            >
                <p className="fstxt-m pb1">
                    <strong className="papyrus">{title}</strong>
                </p>
                <ContentList listclass="ph5" content={content}/>
            </Container>
        )
    }


    return (
        <section id="mat" className="bg-img p-1 mt3"
            // style={{ backgroundImage: `url(${image})` }}
        >
            <div className="grid gap-1 col-3">
                <SectionTitle className="ml1 pt1 pr2 mb1 col-span-3">
                    HEALING MAT <span className="fstxt-s italic"> Can be included in any Skin or Acupuncture Treatment</span>
                </SectionTitle>

                <img className="auto-margin mw-8 col-span-2 border-radius-sm" src={image} alt="mat" />

                <h3 className="col-span-3 txt-center row-start-2 fstxt-m papyrus">
                    Elevate Your Wellness with Advanced Therapies
                </h3>

                {data.map((d, index) => (
                    <Therapy key={index}
                        title={d[0]} className={d[2]} content={d[1]} 
                    />
                ))}

                <p className="col-span-3 txt-center">
                    Experience holistic healing with our HEALING MAT, combining these advanced therapies to support and rejuvenate your body and mind.
                </p>
            </div>
        </section>
    )
}

// Utility
const ContentList = ({ content, listclass, itemclass }) => {
    return (
        <ul className={listclass}>
            {content.map((item, index) => (
                <li key={index} className={itemclass}>
                    <strong>{item[0]} </strong>
                    {item[1]}
                </li>
            ))}
        </ul>
    )
}

const SectionTitle = ({color, alpha=0.8, className, children}) => {
    return (
        <Container
            alpha={alpha}
            type="box"
            pos={className}
            className="wfit-content"
            bg={color}
        >
            <h2 className="h2right txt-center fstxt-l papyrus">{children}</h2>
        </Container>
    )
}


export default Health;