import { Card, NavBar, CopyRight, Title } from "../const"
import Container from "../containers";


function Body(props) {
    const { images } = props

    return (
        <div id="body" className="ctxt-body fstxt-s">
            <NavBar bgColor="body" btnColor="skin"/>

            <section id="hero" className="ph5">
                <Title type="body"></Title>

                <div className="grid gap-1 col-2">
                    <div className="auto-margin">
                        <p>
                            Specializing in AromaTherapeutic BodyWork, Acupressure, and Holistic Health.
                        </p>
                        <ul>
                            <li><u>~AromaTherapy</u> - Tailored essential oil blends</li>
                            <li>~Intuitive Multi-Modality <u>Medical BodyWork Therapies</u></li>
                            <li><u>~Cupping</u></li>
                            <li>~Elector-Acupoint Therapy</li>
                            <li><u>~Acupuncture</u></li>
                            <li>~Wellness and Preventative Consulting</li>
                            <li>~and MORE!</li>
                        </ul>
                    </div>
                    
                    <div style={{backgroundImage: `url(${images.bed})`}} className="bg-img grid content-center h-minium-4"></div>

                    <p className="col-span-2">
                        Featuring personalized protocols designed to clinically address patient/client's concerns.
                        T.A.M.E. TIME blends eastern philosophy with western technology to design a holistic approach
                        for the needs of your health, mind, body, skin, and life.
                    </p>
                </div>
            </section>

            <section id="aromatherapeutic" className="body-light grid gap-1 col-2 p-2">
                <Container type="box" className="txt-center mt3" alpha={0.8} pos="col-span-2">
                    <h1 className="fstxt-l papyrus">AromaTherapeutic Medical BodyWork</h1>
                </Container>

                <img src={images.oil} alt="oils" className="auto-margin"/>

                <Container type="box" alpha={0.9} pos="justify-center"  className="border-radius-sm mw-8 auto-margin">
                    <p className="p-1">
                        <span className="bold">AromaTherapeutic Medical Bodywork,</span> curated by Dr. Tatyana Steele-Hall, blends specific massage techniques, acupressure, meridian theory, and a custom essential oil blend tailored to individual needs. This therapy enhances blood and lymph circulation, calms the nerves, and relaxes the muscles. Dr. Steele-Hall ensures the holistic benefits of this treatment, leaving you feeling renewed, tranquil, and refreshed. 
                    </p>
                </Container>

                <img src={images.massage} alt="body work image" className="max-width auto-margin border-radius-sm"/>
    
                <Card color="body" className="auto-margin col-start-1 row-start-3">
                    <h3 className="txt-center underline fstxt-m papyrus">Features:</h3>
                    <ul className="open-bullets mw-8 p-1">
                        <li>Custom AromaTherapeutic essential oil blend </li>
                        <li>Various massage modalities</li>
                        <li>Deep/strong pressure techniques</li>
                        <li>Electro & Orthopedic Acupuncture</li>
                        <li>Vibration therapy</li>
                        <li>Cupping</li>
                        <li>Myofascial release to break up stress knots</li>
                        <li>Unblocks Qi and energy flow</li>
                        <li>Promotes circulation and relaxation</li>
                    </ul>
                </Card>
            </section>

            <CopyRight/>
        </div>
    )
}


export default Body